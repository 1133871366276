var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "flex justify-between items-center mb-6"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Class Schedule")]), _c('Button', {
    attrs: {
      "buttonText": "Add New Schedule"
    },
    on: {
      "action": _vm.addData
    }
  })], 1), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "flex gap-4"
  }, [_c('div', {
    staticClass: "w-1/4"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Search By Name",
      "enterKeyAction": _vm.filterLmsClass,
      "borderEnabled": "",
      "placeholder": "Search by Schedule Name"
    },
    model: {
      value: _vm.options['name'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'name', $$v);
      },
      expression: "options['name']"
    }
  })], 1), _c('div', {
    staticClass: "w-1/4"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Filter By Class")]), _c('Dropdown', {
    attrs: {
      "options": _vm.coreClassOptions,
      "optionLabel": "nama",
      "default": _vm.coreClassFilterSelected,
      "enableSearch": "",
      "isLoadData": !_vm.isCoreClassDataReady,
      "placeholder": "Select Class"
    },
    on: {
      "onChangeSelected": _vm.filterLmsClass,
      "search": _vm.getCoreClassOptions
    },
    model: {
      value: _vm.coreClassFilterSelected,
      callback: function callback($$v) {
        _vm.coreClassFilterSelected = $$v;
      },
      expression: "coreClassFilterSelected"
    }
  })], 1), _c('div', {
    staticClass: "w-1/4"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Filter By Instructor")]), _c('Dropdown', {
    attrs: {
      "options": _vm.instructorClassOptions,
      "optionLabel": "name",
      "default": _vm.instructorFilterSelected,
      "enableSearch": "",
      "isLoadData": !_vm.isInstructorClassReady,
      "placeholder": "Select Instructor"
    },
    on: {
      "onChangeSelected": _vm.filterLmsClass,
      "search": _vm.getInstructorOptions
    },
    model: {
      value: _vm.instructorFilterSelected,
      callback: function callback($$v) {
        _vm.instructorFilterSelected = $$v;
      },
      expression: "instructorFilterSelected"
    }
  })], 1)]), _c('div', {
    staticClass: "flex justify-start mt-2 mb-6"
  }, [_c('p', {
    staticClass: "text-yellow flex items-center gap-1 cursor-pointer font-semibold",
    on: {
      "click": function click($event) {
        return _vm.resetFilter();
      }
    }
  }, [_c('Trash'), _vm._v("Reset Filter")], 1)]), _c('div', {
    staticClass: "m-content"
  }, [_c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.options.limit,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All',
        setCurrentPage: _vm.options.page + 1
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex flex-col gap-2 whitespace-nowrap"
        }, [_c('div', {
          staticClass: "flex justify-between items-center"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "primary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Document');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: 'white'
            },
            "tooltip": {
              show: true,
              text: 'Report'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.viewReport(props.row.id);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: 'yellow'
            },
            "tooltip": {
              show: true,
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.id, props.row.deliveryType);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "secondary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Duplicate');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: 'yellow'
            },
            "tooltip": {
              show: true,
              text: 'Copy'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.duplicateSchedule(props.row.id, props.row.deliveryType);
            }
          }
        }), _c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.deleteMaster(props.row.id);
            }
          }
        })], 1)]) : props.column.field == 'schedule_text' ? _c('span', [props.row.schedule_text ? _c('span', [_c('span', {
          domProps: {
            "innerHTML": _vm._s(props.row.schedule_text)
          }
        })]) : _c('span', [_vm._v("-")])]) : props.column.field == 'kelas_nama' ? _c('div', [_c('div', {
          staticClass: "nama-kelas"
        }, [_c('router-link', {
          staticClass: "text-yellow font-medium",
          attrs: {
            "to": {
              path: '/kelas',
              query: {
                name: props.formattedRow[props.column.field]
              }
            }
          }
        }, [_vm._v(_vm._s(props.formattedRow[props.column.field]))])], 1)]) : props.column.field == 'nama' ? _c('div', [_c('div', {
          staticClass: "nama-kelas"
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])]) : props.column.field === 'update_kelas' ? _c('div', [props.row.update_kelas ? _c('span', [_c('p', {
          staticStyle: {
            "white-space": "nowrap"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.getClassDate(props.formattedRow[props.column.field]))
          }
        })]) : _c('span', [_vm._v("-")])]) : props.column.field === 'instructur_ids' ? _c('div', _vm._l(props.formattedRow[props.column.field], function (instructor, idx) {
          return _c('p', {
            key: 'instructor-' + idx,
            staticClass: "whitespace-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.getInstructorName(instructor)) + " ")]);
        }), 0) : props.column.field === 'start_date' ? _c('div', [props.row.start_date ? _c('span', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : _c('span', [_vm._v("-")])]) : props.column.field === 'hasIssue' ? _c('div', [_c('StatusLabel', {
          attrs: {
            "status": props.formattedRow[props.column.field] ? 'error' : 'success',
            "statusText": props.formattedRow[props.column.field] ? 'Has issue' : 'No issue'
          }
        })], 1) : props.column.field === 'end_date' ? _c('div', [props.row.end_date ? _c('span', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : _c('span', [_vm._v("-")])]) : props.column.field == 'deliveryType' ? _c('div', [_c('span', {
          staticClass: "text-center block whitespace-nowrap text-white text-sm font-bold py-1 px-3 rounded-md",
          class: [props.row.deliveryType === 'WEBINAR' ? 'bg-neutral' : 'bg-neutral-300']
        }, [_vm._v(_vm._s(props.row.deliveryType.replace('_', '-').toLowerCase().replace(/^\w/, function (c) {
          return c.toUpperCase();
        })))])]) : props.column.field == 'active' ? _c('span', {
          staticClass: "flex flex-col justify-center items-center"
        }, [props.row.active == 1 ? _c('span', {
          staticClass: "block text-green font-bold"
        }, [_vm._v(" Open ")]) : _c('span', {
          staticClass: "block text-red font-bold"
        }, [_vm._v(" Closed ")])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field] || '-') + " ")])];
      }
    }])
  })], 1)])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.showModal,
      "width": "640px",
      "id": "modal"
    },
    on: {
      "close": _vm.toggleModal
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-5"
  }, [_vm._v("Do you want to " + _vm._s(_vm.forActionModal.doWhat) + " this schedule?")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Yes",
      "type": "secondary"
    },
    on: {
      "action": _vm.actionModal
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "No",
      "type": "primary"
    },
    on: {
      "action": _vm.toggleModal
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }