<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>

    <div v-else>
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">Class Schedule</h1>
        <Button buttonText="Add New Schedule" @action="addData" />
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="flex gap-4">
          <div class="w-1/4">
            <TextField type="text" label="Search By Name" :enterKeyAction="filterLmsClass" v-model="options['name']" borderEnabled placeholder="Search by Schedule Name" />
          </div>
          <div class="w-1/4">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter By Class</label>
            <Dropdown
              :options="coreClassOptions"
              optionLabel="nama"
              v-model="coreClassFilterSelected"
              :default="coreClassFilterSelected"
              enableSearch
              :isLoadData="!isCoreClassDataReady"
              @onChangeSelected="filterLmsClass"
              @search="getCoreClassOptions"
              placeholder="Select Class"
            />
          </div>
          <div class="w-1/4">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter By Instructor</label>
            <Dropdown
              :options="instructorClassOptions"
              optionLabel="name"
              v-model="instructorFilterSelected"
              :default="instructorFilterSelected"
              enableSearch
              :isLoadData="!isInstructorClassReady"
              @onChangeSelected="filterLmsClass"
              @search="getInstructorOptions"
              placeholder="Select Instructor"
            />
          </div>
        </div>
        <div class="flex justify-start mt-2 mb-6">
          <p class="text-yellow flex items-center gap-1 cursor-pointer font-semibold" @click="resetFilter()"><Trash />Reset Filter</p>
        </div>
        <div class="m-content">
          <vue-good-table
            mode="remote"
            :pagination-options="{
              enabled: true,
              mode: 'pages',
              perPage: options.limit,
              position: 'bottom',
              perPageDropdown: [5, 10],
              dropdownAllowAll: false,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'All',
              setCurrentPage: options.page + 1
            }"
            :totalRows="totalRecords"
            :rows="data"
            :columns="columns"
            :sort-options="{
              enabled: false
            }"
            @on-page-change="onPageChange"
            @on-per-page-change="onPageChange"
            :row-style-class="rowStyleClassFn"
          >
            <template slot="table-row" slot-scope="props">
              <!-- <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2">
                <Button buttonText="Edit" type="tertiary" size="full" @action="editMaster(props.row.id, props.row.deliveryType)" />
                <Button buttonText="Copy" type="secondary" @action="duplicateSchedule(props.row.id, props.row.deliveryType)" />
                <Button buttonText="Delete" type="delete" @action="deleteMaster(props.row.id)" />
              </div> -->

              <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2 whitespace-nowrap">
                <div class="flex justify-between items-center">
                  <!-- <Button
                    class="mr-2"
                    type="primary"
                    size="icon"
                    :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/DocumentText')"
                    :forIcon="{ height: '24', width: '24', color: 'white' }"
                    :tooltip="{ show: true, text: 'Report' }"
                    @action="goToReport(props.row.id)"
                  /> -->
                  <Button
                    class="mr-2"
                    type="primary"
                    size="icon"
                    :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Document')"
                    :forIcon="{ height: '24', width: '24', color: 'white' }"
                    :tooltip="{ show: true, text: 'Report' }"
                    @action="viewReport(props.row.id)"
                  />
                  <Button
                    class="mr-2"
                    type="tertiary"
                    size="icon"
                    :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                    :forIcon="{ height: '24', width: '24', color: 'yellow' }"
                    :tooltip="{ show: true, text: 'Edit' }"
                    @action="editMaster(props.row.id, props.row.deliveryType)"
                  />
                  <Button
                    class="mr-2"
                    type="secondary"
                    size="icon"
                    :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Duplicate')"
                    :forIcon="{ height: '24', width: '24', color: 'yellow' }"
                    :tooltip="{ show: true, text: 'Copy' }"
                    @action="duplicateSchedule(props.row.id, props.row.deliveryType)"
                  />
                  <Button
                    type="delete"
                    size="icon"
                    :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                    :tooltip="{ show: true, text: 'Delete' }"
                    @action="deleteMaster(props.row.id)"
                  />
                </div>
              </div>

              <span v-else-if="props.column.field == 'schedule_text'">
                <span v-if="props.row.schedule_text">
                  <span v-html="props.row.schedule_text"></span>
                </span>
                <span v-else>-</span>
              </span>
              <div v-else-if="props.column.field == 'kelas_nama'">
                <div class="nama-kelas">
                  <router-link class="text-yellow font-medium" :to="{ path: '/kelas', query: { name: props.formattedRow[props.column.field] } }">{{
                    props.formattedRow[props.column.field]
                  }}</router-link>
                </div>
              </div>
              <div v-else-if="props.column.field == 'nama'">
                <div class="nama-kelas">
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </div>
              <div v-else-if="props.column.field === 'update_kelas'">
                <span v-if="props.row.update_kelas">
                  <p style="white-space: nowrap" v-html="getClassDate(props.formattedRow[props.column.field])"></p>
                </span>
                <span v-else>-</span>
              </div>
              <div v-else-if="props.column.field === 'instructur_ids'">
                <p v-for="(instructor, idx) in props.formattedRow[props.column.field]" :key="'instructor-' + idx" class="whitespace-nowrap">
                  {{ getInstructorName(instructor) }}
                </p>
              </div>
              <div v-else-if="props.column.field === 'start_date'">
                <span v-if="props.row.start_date">
                  <span style="display: block; white-space: nowrap">
                    {{ formatDate(props.formattedRow[props.column.field]).date }}
                  </span>
                  <span style="display: block; font-size: 14px; color: #333">
                    {{ formatDate(props.formattedRow[props.column.field]).time }}
                  </span>
                </span>
                <span v-else>-</span>
              </div>
              <div v-else-if="props.column.field === 'hasIssue'">
                <StatusLabel :status="props.formattedRow[props.column.field] ? 'error' : 'success'" :statusText="props.formattedRow[props.column.field] ? 'Has issue' : 'No issue'" />
              </div>
              <div v-else-if="props.column.field === 'end_date'">
                <span v-if="props.row.end_date">
                  <span style="display: block; white-space: nowrap">
                    {{ formatDate(props.formattedRow[props.column.field]).date }}
                  </span>
                  <span style="display: block; font-size: 14px; color: #333">
                    {{ formatDate(props.formattedRow[props.column.field]).time }}
                  </span>
                </span>
                <span v-else>-</span>
              </div>
              <div v-else-if="props.column.field == 'deliveryType'">
                <span class="text-center block whitespace-nowrap text-white text-sm font-bold py-1 px-3 rounded-md" :class="[props.row.deliveryType === 'WEBINAR' ? 'bg-neutral' : 'bg-neutral-300']">{{
                  props.row.deliveryType
                    .replace('_', '-')
                    .toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase())
                }}</span>
              </div>
              <span v-else-if="props.column.field == 'active'" class="flex flex-col justify-center items-center">
                <span v-if="props.row.active == 1" class="block text-green font-bold"> Open </span>
                <span v-else class="block text-red font-bold"> Closed </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] || '-' }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
    <Modal :modalVisible="showModal" @close="toggleModal" width="640px" id="modal">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-5">Do you want to {{ forActionModal.doWhat }} this schedule?</p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Yes" type="secondary" @action="actionModal" />
            <Button buttonText="No" type="primary" @action="toggleModal" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
  name: 'JadwalKelas',
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    StatusLabel: () => import(/* webpackChunkName: "StatusLabel" */ '@/components/Label/StatusLabel'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Trash: () => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')
  },
  data: function () {
    return {
      forActionModal: { doWhat: '', id: '', deliveryType: '' },
      showModal: false,
      searchTerm: null,
      options: {
        limit: 10,
        page: 0,
        name: '',
        core_class_id: '',
        instructor_id: ''
      },
      instructorFilterSelected: null,
      coreClassFilterSelected: null,
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      coreClassOptions: [],
      instructorClassOptions: [],
      isCoreClassDataReady: true,
      isInstructorClassReady: true
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('kelas', ['GET_LIST_KELAS']),
    ...mapActions('instructur', ['GET_LIST_INSTRUCTUR_V3']),
    async getData() {
      this.list_instructur = await this.getAsyncInstructorList()
      this.initData(this.options)
    },
    getCoreClassOptions(name) {
      this.isCoreClassDataReady = false
      const params = queryString.stringify({
        name,
        limit: 10
      })
      this.GET_LIST_KELAS({ params, customerId: this.clientId }).then((res) => {
        if (res?.data?.code === 200) {
          this.coreClassOptions = res?.data?.data
        }
        this.isCoreClassDataReady = true
      })
    },
    getInstructorOptions(keyword) {
      this.isInstructorClassReady = false
      const params = queryString.stringify({
        keyword,
        direction: 'DESC',
        page: 0,
        size: 10,
        sortBy: 'createdDate'
      })
      this.GET_LIST_INSTRUCTUR_V3({ params, customerId: this.clientId }).then((res) => {
        if (res?.data?.code === 200) {
          this.instructorClassOptions = res?.data?.data
        }
        this.isInstructorClassReady = true
      })
    },
    getAsyncInstructorList() {
      return new Promise((resolve) => {
        const params = queryString.stringify({
          keyword: '',
          direction: 'DESC',
          page: 0,
          size: 500,
          sortBy: 'createdDate'
        })
        this.GET_LIST_INSTRUCTUR_V3({ params, customerId: this.clientId }).then((res) => {
          if (res?.data?.code === 200) {
            resolve(res?.data?.data)
            // this.list_instructur = res?.data?.data
          } else {
            resolve([])
          }
        })
      })
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    getClassDate(data) {
      const dates = data.split(',')
      return dates.join('<br/>')
    },
    resetFilter() {
      const options = {
        limit: 10,
        page: 0,
        name: '',
        core_class_id: '',
        instructor_id: ''
      }
      this.options = options
      this.instructorFilterSelected = null
      this.coreClassFilterSelected = null
      this.initData(options)
    },
    getInstructorName(instructor) {
      const selectedInstructor = this.list_instructur.filter((item) => item.id === instructor)
      return selectedInstructor[0]?.name || '-'
    },
    initData(options) {
      this.showLoading()
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      const customerId = this.clientId
      if (this.lmsClassName) {
        options.name = this.lmsClassName
      }
      let paramsTemp = queryString.stringify({
        ...options,
        lms_class_id: this.lmsClassId
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
          this.hideLoading()
        })
    },
    filterLmsClass() {
      this.options.limit = 10
      this.options.page = 0
      const options = {
        ...this.options,
        core_class_id: this.coreClassFilterSelected?.id,
        instructor_id: this.instructorFilterSelected?.id,
        limit: 10,
        page: 0
      }
      this.initData(options)
    },
    getListInstructur() {
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: 'name'
        }
      })
      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          this.list_instructur = this.$store.getters['instructur/list_instructur'] ? this.$store.getters['instructur/list_instructur'] : []
        })
        .catch(function () {})
    },

    getListKelas() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          this.list_kelas = this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
        })
        .catch(function () {})
    },

    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password infalid')
      }
    },
    preview(masterId) {
      this.$router.push('/jadwalkelas/' + masterId)
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage - 1
      const options = {
        ...this.options,
        core_class_id: this.coreClassFilterSelected?.id,
        instructor_id: this.instructorFilterSelected?.id,
        limit: params.currentPerPage,
        page: params.currentPage - 1
      }
      this.initData(options)
    },
    searchByClass() {
      this.options.core_class_id = this.options.core_class_id.id
      this.initData()
    },
    searchByInstructor() {
      this.options.instructor_id = this.options.instructor_id.id
      this.initData()
    },
    onSearch() {
      this.options.page = 0
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: 10,
          page: 0
        },
        ...this.options
      })
      this.showLoading()
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          this.hideLoading()
        })
    },
    editMaster(masterId, deliveryType) {
      if (deliveryType === 'WEBINAR') {
        this.$router.push('/jadwalkelas/edit/' + masterId)
      } else {
        this.$router.push('/jadwalkelas/new-schema/' + masterId)
      }
    },
    viewReport(masterId) {
      this.$router.push(`/jadwalkelas/report/${masterId}`)
    },
    toggleModal() {
      this.showModal = !this.showModal
    },
    duplicateSchedule(id, deliveryType) {
      this.forActionModal = { doWhat: 'copy', id, deliveryType }
      this.toggleModal()
    },
    deleteMaster(id) {
      this.forActionModal = { doWhat: 'delete', id, deliveryType: '' }
      this.toggleModal()
    },
    actionModal() {
      const forAction = this.forActionModal
      switch (forAction.doWhat) {
        case 'copy':
          forAction.deliveryType === 'WEBINAR' ? this.$router.push(`/jadwalkelas/copy/${forAction.id}`) : this.$router.push(`/jadwalkelas/new-schema/copy/${forAction.id}`)
          break
        case 'delete':
          this.$store
            .dispatch('jadwalkelas/DELETE_JADWALKELAS', {
              masterType: this.$route.meta.name,
              masterId: forAction.id,
              customerId: this.clientId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                this.initData(this.options)
              }
            })
          break
      }
      this.toggleModal()
    },
    sendCertificate(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: 'name',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('jadwalkelas/DELETE_JADWALKELAS', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                // this.$swal(resp.data.message)
                this.$store.dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
                  masterType: this.$route.meta.name,
                  customerId: this.clientId,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },
    addData() {
      if (this.clientId == 1) {
        this.$router.push('/' + this.$route.meta.name + '/select')
      } else {
        this.$router.push('/jadwalkelas/new-schema')
      }
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.active == 1 ){
      //     result='green'
      // } else if (row.active == 0 ){
      //     result='red'
      // }
      return result
    }
  },
  computed: {
    clientId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['jadwalkelas/list_jadwalkelas'] ? this.$store.getters['jadwalkelas/list_jadwalkelas'] : []
    },
    totalRecords: function () {
      return this.$store.getters['jadwalkelas/paginate'].total ? this.$store.getters['jadwalkelas/paginate'].total : 0
    },
    columns: function () {
      return [
        // { field: 'id', label: 'Schedule Id' },
        { field: 'nama', label: 'Schedule ' },
        { field: 'active', label: 'Status' },
        { field: 'hasIssue', label: 'Issue' },
        // { field: 'core_class_id', label: 'Class Id' },
        { field: 'kelas_nama', label: 'Class' },
        { field: 'deliveryType', label: 'Class Type' },
        { field: 'scheduleCodePMO', label: 'Schedule Code' },
        { field: 'batch', label: 'Batch' },
        // { field: "jadwal", label: "Hari" },
        // { field: 'update_kelas', label: 'Date' },
        { field: 'start_date', label: 'Start Date' },
        { field: 'end_date', label: 'End Date' },
        { field: 'instructur_ids', label: 'Instructors' },
        { field: 'link_wa', label: 'Whatsapp Link' },
        { field: 'link_zoom', label: 'Zoom Link' },
        { field: 'host_key', label: 'Host Key' },
        // { field: 'exam_url', label: 'Exam Link' },
        // { field: 'pretest_url', label: 'Pretest Link' },
        { field: 'jumlah_peserta', label: 'Number of Participants' },
        { field: 'max_peserta', label: 'Max Participants' },
        { field: 'schedule_text', label: 'Schedule' },
        // { field: 'instructor_id', label: 'Instructor Id' },
        // { field: 'jumlah_peserta_manual', label: 'Manually Input Number of Participants' },
        // { field: 'pretest_exercise_id', label: 'Pretest Exercise Id' },
        // { field: 'exam_exercise_id', label: 'Exam Exercise Id' },
        // { field: 'customer_id', label: 'Customer Id' },
        // { field: 'java_instructor_id', label: 'Java Instructor Id' },
        // { field: 'created_date', label: 'Created Date' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    filters: function () {
      return [
        { field: 'id', label: 'Id' },
        { field: 'core_class_id', label: 'Id Kelas' },
        { field: 'instructor_id', label: 'Id Instructur' },
        { field: 'name', label: 'Nama' }
        // { field: 'link_wa', label: 'Link WA' }
        // { field: 'active', label: 'Active' }
      ]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    },
    lmsClassId() {
      return this.$route.query.id
    },
    lmsClassName() {
      return this.$route.query.name
    }
  },
  created: function () {
    this.getData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}

.green {
  background: linear-gradient(#f4f5f8, #57c137);
}

.default {
  background-color: #fff;
}

.nama-kelas {
  min-width: 300px;
}

.m-content {
  &::v-deep .vgt-table thead tr th {
    white-space: nowrap;
    padding: 0.75em;
  }
}
</style>
